import * as React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { IAppState } from '../../stores';
import AutoEditor from '../../components/auto/AutoEditor';
import UserList from '../../components/identity/UserList';
import { fetchProfile } from '../../stores/profile/actions';
import LoadingScreen from '../../components/common/LoadingScreen';
import { useQueryParams } from '../../abstracts/NavigationHelpers';
import DefaultAutoGrid from '../../components/auto/DefaultAutoGrid';
import { IConnectedProps, mapConfigFetchToProps } from '../../components/auto/AutoGrid';
import { Box, Typography, Paper, AppBar, Tabs, Tab, Theme, useTheme } from '@mui/material';

const generateSxClasses = (theme: Theme) => {
  return {
    boxContent: {
      marginTop: theme.spacing(2)
    },
    contentWrapper: {
      margin: theme.spacing(2)
    },
    contentWrapperTitled: {
      marginTop: 0,
      mx: theme.spacing(3),
      marginBottom: theme.spacing(2)
    },
    paper: {
      margin: 'auto',
      overflow: 'hidden',
      marginBottom: theme.spacing(1.25)
    },
  };
};

interface ILocalConnectedProps extends Omit<IConnectedProps, 'classes'> {
  fetchProfile: typeof fetchProfile;
}

interface ICompanyEditProps {
  companyId?: number;
  onCompanySave?: (newId: number) => void;
}

const tabs = ['logins', 'contacts', 'addresses', 'staff'] as const;
type TabValues = typeof tabs[number];

interface ICompanyEditState {
  loadedTabs: TabValues[];
  canEdit: boolean;
}

const CompanyEdit = (props: ILocalConnectedProps & ICompanyEditProps) => {
  const theme = useTheme();
  const sxClasses = generateSxClasses(theme);
  const { profile, companyId, onCompanySave } = props;

  const [{ tab }, setParams] = useQueryParams<{ tab: TabValues }>({
    tab: {
      type: 'string',
      defaultValue: typeof companyId === 'undefined' ? undefined : 'logins',
      allowedValues: JSON.parse(JSON.stringify(tabs))
    }
  }, 'company');

  const handleUserSelected = () => {};
  const handleUserAddNew = () => {};

  const displayCompany = (): boolean => profile.isTrainingFacility || (companyId != null && (profile.companies?.filter((x) => x.companyId === companyId).length ?? 0) > 0);

  const canEditCompany = (): boolean => {
    if (profile.isAdmin && profile.isTrainingFacility) return true;

    if (companyId == null) return false;

    return profile.companies.find((x) => x.companyId === companyId)?.isAdmin ?? false;
  };

  const [editState, setEditState] = React.useState<ICompanyEditState>({
    loadedTabs: [],
    canEdit: canEditCompany()
  });

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: TabValues) => {
    setEditState((old) => ({
      ...old,
      loadedTabs: [newValue].concat(old.loadedTabs)
    }));

    setParams({ tab: newValue });
  };

  const handleSave = (newId: number) => {
    if (onCompanySave) onCompanySave(newId);
  };

  React.useEffect(() => {
    setEditState({
      loadedTabs: [tab],
      canEdit: canEditCompany()
    });
  }, [companyId, profile.isTrainingFacility, profile.isAdmin]);

  return profile.userId < 0 ? (
    <LoadingScreen/>
  ) : (
    <>
      <Paper sx={{ ...sxClasses.paper }}>
        <Box sx={{ ...sxClasses.contentWrapper }}>
          {displayCompany() ? (
            <AutoEditor baseTable="identity.company" editId={companyId} onSave={handleSave} mode="inline" readonly={!profile.isAdmin || !profile.isTrainingFacility}/>
          ) : (
            <Typography>Unable to open company</Typography>
          )}
        </Box>
      </Paper>
      {!displayCompany() || companyId == null ? undefined : (
        <Paper sx={{ ...sxClasses.paper }}>
          <Box sx={{ ...sxClasses.contentWrapperTitled }}>
            <AppBar sx={{ mb: theme.spacing(1) }} position="relative">
              <Tabs value={tab} onChange={handleTabChange} variant="fullWidth" aria-label="Company details options">
                <Tab label="Logins" value="logins"/>
                <Tab label="Contacts" value="contacts"/>
                <Tab label="Addresses" value="addresses"/>
                <Tab label="Associated Workers" value="staff"/>
              </Tabs>
            </AppBar>
            {editState.loadedTabs.indexOf('logins') < 0 ? undefined : (
              <UserList hidden={tab !== 'logins'} companyId={companyId} onUserSelected={handleUserSelected} onAddNew={handleUserAddNew} readonly={!editState.canEdit}/>
            )}
            {editState.loadedTabs.indexOf('contacts') < 0 ? undefined : (
              <Box sx={{ ...sxClasses.boxContent }} hidden={tab !== 'contacts'}>
                <DefaultAutoGrid
                  tableName="identity.companyContact"
                  defaultColumnFilters={[{ column: 'company', filter: companyId, hideFromTable: true, defaultInEditor: 'readonly' }]}
                  autoEditor={editState.canEdit}
                  autoDelete={editState.canEdit}
                />
              </Box>
            )}
            {editState.loadedTabs.indexOf('addresses') < 0 ? undefined : (
              <Box sx={{ ...sxClasses.boxContent }} hidden={tab !== 'addresses'}>
                <DefaultAutoGrid
                  tableName="identity.companyAddress"
                  defaultColumnFilters={[{ column: 'company', filter: companyId, hideFromTable: true, defaultInEditor: 'readonly' }]}
                  autoEditor={editState.canEdit}
                  autoDelete={editState.canEdit}
                />
              </Box>
            )}
            {editState.loadedTabs.indexOf('staff') < 0 ? undefined : (
              <Box sx={{ ...sxClasses.boxContent }} hidden={tab !== 'staff'}>
                <DefaultAutoGrid
                  tableName="identity.personCompany"
                  defaultColumnFilters={[{ column: 'company', filter: companyId, hideFromTable: true, defaultInEditor: 'readonly' }]}
                  autoEditor={editState.canEdit}
                  autoDelete={editState.canEdit}
                  columns={['person.iecNumber', 'person.firstLegal', 'person.firstPreferred', 'person.last', 'start', 'end']}
                  pagination
                  buttonTitle="Add Worker to Company"
                  editorTitle="Worker Association"
                />
              </Box>
            )}
          </Box>
        </Paper>
      )}
    </>
  );
};

function mapStateToProps(state: IAppState) {
  return {
    ui: state.ui,
    profile: state.profile,
    db: state.db
  };
}

export default compose(connect(mapStateToProps, mapConfigFetchToProps))(CompanyEdit);
