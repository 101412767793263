import React from 'react';
import moment from 'moment';
import settings from '../../../abstracts/settings';
import { createStyles, withStyles } from '@mui/styles';
import { FormControl, TextField } from '@mui/material';
import { AutoEditorRowFunction } from '../AutoEditorField';
import { IColumn } from '../../../stores/database/interfaces';
import { IAutoEditorImplementation } from '../autoInterfaces';

const styles = () => createStyles({});

export interface IDateFieldProps {
  min?: string | AutoEditorRowFunction<string>;
  max?: string | AutoEditorRowFunction<string>;
  includeSeconds?: boolean;
  type: 'Date' | 'Time' | 'DateTime';
}

interface IDateEditorProps extends IAutoEditorImplementation<string | undefined>, IDateFieldProps {
  classes: any;
}

interface IDateTextEditorState {
  min?: string;
  max?: string;
  date?: moment.Moment;
}

const DateTextEditor = (props: IDateEditorProps) => {
  const { field, editValue, error, warning, onValueUpdated } = props;

  const getMinMax = (defaultVal?: string, val?: string | AutoEditorRowFunction<string>): string | undefined => {
    if (val != null) {
      if (typeof val === 'string') {
        return val;
      }

      return val(props.item, props.field);
    }

    return defaultVal;
  };

  const [minDate, maxDate] = props.type === 'Time' ? [undefined, undefined] : ['2000-01-01', '2100-01-01'];

  const [value, setValue] = React.useState<IDateTextEditorState>({
    min: getMinMax(minDate, props.min),
    max: getMinMax(maxDate, props.max)
  });

  const [controlValue, setControlValue] = React.useState('');

  const parseEditValue = (parseValue?: string): moment.Moment | undefined => {
    if (parseValue == null) return undefined;

    switch (props.type) {
      case 'Date':
        return moment(parseValue, settings.apiDateFormatMoment);
      case 'DateTime':
        return moment(parseValue, settings.apiDateTimeFormatMoment);
      case 'Time':
        return moment(`2000-01-01 ${parseValue}`, settings.apiDateTimeFormatMoment);
    }
  };

  const parseDisplayValue = (column: IColumn, parseValue?: string): string => {
    if (parseValue == null) return '';

    switch (props.type) {
      case 'Date':
        return moment(parseValue).format(settings.dateFormatMoment);
      case 'Time':
        return column.name.toLowerCase().endsWith('utc') ? moment.utc(`1900-01-01 ${parseValue}`).local().format(settings.timeFormatMoment) : moment(`1900-01-01 ${parseValue}`).format(settings.timeFormatMoment);
      case 'DateTime':
        return column.name.toLowerCase().endsWith('utc') ? moment.utc(parseValue).local().format(settings.dateTimeFormatMoment) : moment(parseValue).format(settings.dateTimeFormatMoment);
    }
  };

  React.useEffect(() => {
    const momentDate = parseEditValue(props.editValue);

    if (momentDate) {
      let inputValue = '';

      switch (props.type) {
        case 'Date':
          inputValue = momentDate.format(settings.inputs.date);
          break;
        case 'DateTime':
          if (!props.includeSeconds) {
            momentDate.seconds(0);
          }

          inputValue = momentDate.format(props.includeSeconds ? settings.inputs.dateTime : settings.inputs.dateTimeSeconds);
          break;
        case 'Time':
          if (!props.includeSeconds) {
            momentDate.seconds(0);
          }

          inputValue = momentDate.format(props.includeSeconds ? settings.inputs.time : settings.inputs.timeSeconds);
          break;
      }

      setControlValue(inputValue);
    }
  }, []);

  React.useEffect(() => {
    setValue(() => ({
      min: getMinMax(minDate, props.min),
      max: getMinMax(maxDate, props.max),
      date: parseEditValue(props.editValue)
    }));
  }, [props.editValue, props.min, props.max]);

  const handleFieldUpdate = (text: string): void => {
    setControlValue(text);

    onValueUpdated(text, field, parseDisplayValue(field, text));
  };

  const renderControl = () => {
    switch (props.type) {
      case 'Date':
        return (
          <TextField
            disabled={props.disabled}
            error={!!error}
            helperText={error || warning || field.description}
            id={`dateEditor_${field.columnId}`}
            inputProps={{ min: value.min, max: value.max }}
            InputProps={{ endAdornment: props.endAdornment }}
            InputLabelProps={{ shrink: true }}
            label={field.title}
            onChange={event => handleFieldUpdate(event.currentTarget.value)}
            onKeyDown={event => {
              if (event.keyCode === 27) setValue(old => ({ ...old, text: editValue }));
            }}
            type="date"
            value={controlValue}
          />
        );
      case 'DateTime':
        return (
          <TextField
            disabled={props.disabled}
            error={!!error}
            helperText={error || warning || field.description}
            id={`dateEditor_${field.columnId}`}
            inputProps={{ min: value.min, max: value.max, step: props.includeSeconds ? 1 : 60 }}
            InputProps={{ endAdornment: props.endAdornment }}
            InputLabelProps={{ shrink: true }}
            label={field.title}
            onChange={event => handleFieldUpdate(event.currentTarget.value)}
            onKeyDown={event => {
              if (event.keyCode === 27) setValue(old => ({ ...old, text: editValue }));
            }}
            type="datetime-local"
            value={controlValue}
          />
        );
      case 'Time':
        return (
          <TextField
            disabled={props.disabled}
            error={!!error}
            helperText={error || warning || field.description}
            id={`dateEditor_${field.columnId}`}
            inputProps={{ min: value.min, max: value.max, step: props.includeSeconds ? 1 : 60 }}
            InputProps={{ endAdornment: props.endAdornment }}
            InputLabelProps={{ shrink: true }}
            label={field.title}
            onChange={event => handleFieldUpdate(event.currentTarget.value)}
            onKeyDown={event => {
              if (event.keyCode === 27) setValue(old => ({ ...old, text: editValue }));
            }}
            type="time"
            value={controlValue}
          />
        );
    }
  };

  return (
    <FormControl sx={{ marginTop: '8px' }} id={`dateEditorLabel_${field.columnId}`} fullWidth error={!!error} required={field.required}>
      {renderControl()}
    </FormControl>
  );
};

export default withStyles(styles)(DateTextEditor);
