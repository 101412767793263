import React from 'react';
import moment from 'moment';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { ComponentType } from 'react';
import clsx, { ClassValue } from 'clsx';
import { Link } from 'react-router-dom';
import { useDebounce } from 'use-debounce';
import { Dictionary, isEmpty } from 'lodash';
import settings from '../../abstracts/settings';
import { IUiState } from '../../stores/ui/types';
import { IDisplayField } from './autoInterfaces';
import DisplayField from '../common/DisplayField';
import { withStyles, createStyles } from '@mui/styles';
import { ChangeMessage, autoChange } from './AutoChange';
import { createAlertBulk } from '../../stores/ui/actions';
import { IProfileState } from '../../stores/profile/types';
import { fetchProfile } from '../../stores/profile/actions';
import LoadingScreen from '../../components/common/LoadingScreen';
import { customNavigate } from '../../abstracts/NavigationHelpers';
import { MoreVert, GetApp, Menu as MenuIcon } from '@mui/icons-material';
import { MdClear, MdDeleteSweep, MdDoneAll, MdEditNote } from 'react-icons/md';
import { fetchConfigRequest, refreshCacheData } from '../../stores/database/actions';
import { IDatabaseState, mapProfileDbFromAppState } from '../../stores/database/types';
import { objectMap, arrayMapObject, objectFilterString, objectEach } from '../../abstracts/DataroweHelpers';
import { HiddenFormatter, IconFormatter, RowFormatter } from '../../stores/database/gridExtension/interfaces';
import { ISelectColumnMap, ColumnOrderType, isSelectColumnMap, SpecialReferenceType } from '../../stores/database/interfaces';
import { ILocalTextFilter, ILocalNumberFilter, ILocalDateTimeFilter, sortFunction, LocalFilters, filterColumn } from './AutoLocalFilter';
import { AutoLoadKeyDisplay, ICustomSelectColumnMap, ICustomDisplayDefinition, AutoRow, postGenerateExcel, isICustomSelectColumnMap } from './AutoLoad';
import { Theme, Table, TableCell, TableRow, TableHead, TableBody, Checkbox, IconButton, Menu, MenuItem, ListItemIcon, Typography, TableSortLabel, TablePagination, TableContainer, Dialog, DialogTitle, DialogContent, Grid, DialogActions, Button, Divider, TextField, ListItemText, List, ListItem, ListItemButton } from '@mui/material';

const styles = (theme: Theme) => createStyles({
  flexContainer: {
    display: 'flex',
    alignItems: 'center',
    boxSizing: 'border-box'
  },
  tableRow: {
    cursor: 'pointer'
  },
  tableRowHover: {
    '&:hover': {
      backgroundColor: theme.palette.grey[100]
    }
  },
  tableCell: {
    flex: 1
  },
  noClick: {
    cursor: 'initial'
  },
  denseCell: {
    padding: '0px 3px 0px 3px'
  },
  tableWrapper: {
    width: '100%',
    overflow: 'auto'
  },
  emptyMessage: {
    textAlign: 'center',
    color: theme.palette.grey[500],
    fontStyle: 'italic'
  }
});

// TODO: Add local state on sort, filter, and sorted array; add a hook that subscribes to the rowData that resorts/filters the updated dataset (maybe just sorts and removes filters?)

export type RowBoolean = (row: AutoRow, key: string | number, profile: IProfileState, stateExtension?: any) => boolean;

export interface IActionButton<R = AutoRow> {
  icon?: React.ReactElement;
  iconFunction?: IconFormatter;
  title: string;
  onClick: (row: R, key: string | number, stateExtension?: any) => void;
  disabled?: boolean | RowBoolean;
  hidden?: HiddenFormatter<R>;
}

export type RowClasses = (rowData: Dictionary<any>) => ClassValue[] | undefined;
export type AutoGridActions<R = AutoRow> = IActionButton<R> | (IActionButton<R>[] | IActionButton<R>)[];
export type AutoGridMode = 'view' | 'bulk-edit' | 'bulk-delete';

export interface IAutoGridProps<T = AutoRow, K = ISelectColumnMap | ISelectColumnMap[] | string | undefined, D = ISelectColumnMap | RowFormatter<string, undefined> | undefined> {
  // [x: string]: any;
  exportTitle: string;
  baseTable?: number | string;
  keyField: K;
  displayField: D;
  displayColumns: (ICustomSelectColumnMap | ICustomDisplayDefinition<T>)[];
  defaultSortColumn?: { column: ICustomSelectColumnMap | ICustomDisplayDefinition; sortOrder?: ColumnOrderType } | number;
  defaultSortOrder?: ColumnOrderType;
  additionalColumns?: ISelectColumnMap[];
  selectedRows?: AutoLoadKeyDisplay;
  disabledSelects?: string[] | boolean;
  rowData: Dictionary<T>;
  rowClasses?: RowClasses;
  quickTextFilter?: string;
  columnFilter?: { [column: string]: LocalFilters };
  rowDataLastUpdate: number;
  rowDataLoading?: boolean;
  height?: number | string;
  marginBottom?: number | string;
  emptyMessage?: string;
  minHeight?: number | string;
  maxHeight?: number | string;
  cacheData?: Dictionary<any>;
  preActions?: AutoGridActions<T>;
  postActions?: AutoGridActions<T>;
  menuActions?: IActionButton<T>[];
  hideMenuActions?: boolean;
  rowNumbers?: boolean;
  stateExtension?: any;
  dense?: boolean;
  pagination?: boolean | number[];
  defaultPagination?: number;
  bulkEdit?: boolean;
  bulkDelete?: boolean;
  onBulkDelete?: (deleteIds: string[], success: boolean, messages: ChangeMessage[]) => void;
}

export interface AutoGridRowClickProps {
  event: React.MouseEvent<unknown>;
  id: string;
  name: string;
  updatedSelectedRows: AutoLoadKeyDisplay | undefined;
}

export type AutoGridRowClickEvent = (props: AutoGridRowClickProps, cacheData?: Dictionary<any>) => void;
export type AutoGridSelectAllEvent = (props: { event: React.ChangeEvent<HTMLInputElement>; checked: boolean; updatedSelectedRows: AutoLoadKeyDisplay }, cacheData?: Dictionary<any>) => void;

export interface IAutoGridEvents {
  onRowClick?: AutoGridRowClickEvent;
  onSelectAllClick?: AutoGridSelectAllEvent;
}

export const handleNewlineCharacterInText = (item: string | React.ReactNode | undefined): string | React.ReactNode | undefined => {
  if (item == null) return undefined;

  if (typeof item === 'string' && item.indexOf('\n') > 0) {
    return (
      <React.Fragment>
        {item.split('\n').map((v, i) => (
          <span key={i}>
            {v}
            <br />
          </span>
        ))}
      </React.Fragment>
    );
  }

  return item;
};

export const getBoolCheckDisplay = (b: boolean) => (b ? '✓' : '');

export const displaySpecialReferenceType = (type: SpecialReferenceType, value: any, linkNewPage?: boolean): string | React.ReactNode | undefined => {
  switch (type){
    case 'IECNumber':
      return <Link style={{ color: '#3391FF' }} {...(linkNewPage ? { target: '_blank', rel: 'noreferrer' } : {})} to={customNavigate.toWorker(value)}>{value}</Link>;
    default: throw new Error(`Unhandled special reference type ${type} for value ${value}`);
  }
};

export const getCellDisplay = (props: { row: AutoRow, key: string | number, column: ICustomSelectColumnMap | ICustomDisplayDefinition, stateExtension?: any, forceText?: boolean, linkNewPage?: boolean }): string | React.ReactNode | undefined => {
  const { row, key, column, stateExtension, forceText, linkNewPage } = props;

  if (row[column.columnAlias] == null && column.display == null && column.displayControl == null) return '';

  if (isICustomSelectColumnMap(column)) {
    if (column.displayControl != null && !forceText) {
      return typeof column.displayControl === 'string' ? displaySpecialReferenceType(column.displayControl, row[column.columnAlias], linkNewPage) : handleNewlineCharacterInText(column.displayControl(row, key, column, stateExtension));
    }

    if (column.display != null) {
      return handleNewlineCharacterInText(column.display(row, key, column, stateExtension));
    }

    switch (column.columnType) {
      case 'Date':
        return moment(row[column.columnAlias]).format(settings.dateFormatMoment);
      case 'Time':
        return column.columnAlias.toLowerCase().endsWith('utc') ? moment.utc(`1900-01-01 ${row[column.columnAlias]}`).local().format(settings.timeFormatMoment) : moment(`1900-01-01 ${row[column.columnAlias]}`).format(settings.timeFormatMoment);
      case 'DateTime':
        return column.columnAlias.toLowerCase().endsWith('utc') ? moment.utc(row[column.columnAlias]).local().format(settings.dateTimeFormatMoment) : moment(row[column.columnAlias]).format(settings.dateTimeFormatMoment);
      case 'Bool':
        return getBoolCheckDisplay(row[column.columnAlias]);
      case 'Money':
        return new Intl.NumberFormat('en-CA', { style: 'currency', currency: 'CAD' }).format(row[column.columnAlias]);
      default:
        return handleNewlineCharacterInText(row[column.columnAlias]);
    }
  }

  if (column.displayControl != null && !forceText) {
    return typeof column.displayControl === 'string'
      ? displaySpecialReferenceType(column.displayControl, row[column.columnAlias], linkNewPage )
      : handleNewlineCharacterInText(column.displayControl(row, key, column.columnAlias, stateExtension));
  }

  if (column.display != null) {
    return handleNewlineCharacterInText(column.display(row, key, column.columnAlias, stateExtension));
  }

  return handleNewlineCharacterInText(row[column.columnAlias]);
};

export const getDisplayFields = (row: AutoRow, key: string | number, displayColumns: (ICustomSelectColumnMap | ICustomDisplayDefinition)[], indexes: number[], stateExtension?: any): IDisplayField[] => {
  const result: IDisplayField[] = [];
  indexes.forEach((idx) => {
    if (idx < displayColumns.length) {
      const title = displayColumns[idx].columnTitle;
      result.push({
        label: typeof title === 'string' ? title : title.export,
        value: getCellDisplay({ row, key, stateExtension, column: displayColumns[idx] })
      });
    }
  });

  return result;
};

export const keyFieldString = (row: AutoRow, keyField: ISelectColumnMap | ISelectColumnMap[] | string): string | undefined => {
  if (keyField == null) return undefined;

  if (typeof keyField === 'string') return row[keyField];

  if (Array.isArray(keyField)) {
    return keyField.map((x) => row[x.columnAlias]).join('_');
  }

  return row[keyField.columnAlias];
};

export interface IConnectedProps {
  classes: any;
  profile: IProfileState;
  db: IDatabaseState;
  ui: IUiState;
  fetchConfigRequest: typeof fetchConfigRequest;
  refreshCacheData: typeof refreshCacheData;
  createAlertBulk: typeof createAlertBulk;
}

export interface IConnectedPropsNoClasses {
  profile: IProfileState;
  db: IDatabaseState;
  ui: IUiState;
  fetchConfigRequest: typeof fetchConfigRequest;
  refreshCacheData: typeof refreshCacheData;
  createAlertBulk: typeof createAlertBulk;
}

export interface IGridMenuOptions {
  tableNoExcel?: boolean;
}

export const getDisplayField = (row: Dictionary<any>, key: string | number, displayField?: ISelectColumnMap | RowFormatter<string>): string => {
  if (displayField == null) {
    throw new Error(`Missing Config - display field and key field are required in AutoGrids: ${JSON.stringify(row)}`);
  }

  if (isSelectColumnMap(displayField)) {
    return row[displayField.columnAlias];
  }

  return displayField(row, key, undefined);
};

const AutoGrid = (props: IAutoGridProps & IConnectedProps & IAutoGridEvents & IGridMenuOptions) => {
  const { stateExtension } = props;
  const [debounceQuickTextFilter] = useDebounce(props.quickTextFilter, 500);

  React.useEffect(() => {
    props.fetchConfigRequest();

    return () => { };
  }, []);

  interface ISortedRow {
    rowKey: string;
    filterShow: boolean;
  }

  interface IAutoGridState {
    sortedRows: ISortedRow[];
    displayRows: string[];
    sortColumn?: ICustomSelectColumnMap | ICustomDisplayDefinition;
    sortOrder: ColumnOrderType;
    lastPropLoad: number;
    lastRowFilterUpdate: number;
    instantFilters: {
      [column: string]: ILocalTextFilter | ILocalNumberFilter | ILocalDateTimeFilter;
    };
    rowsPerPage: number;
    page: number;
    menuAnchor?: HTMLElement;
    currentMode: AutoGridMode;
    bulkSelectedRows: AutoLoadKeyDisplay;
    bulkConfirm: boolean;
    bulkDeleteComment?: string;
    bulkDeleteDetailKey?: string;
  }

  const defaultLocalState: IAutoGridState = {
    sortedRows: [],
    displayRows: [],
    sortColumn: typeof props.defaultSortColumn === 'number' ? props.displayColumns[props.defaultSortColumn] : props.defaultSortColumn?.column,
    sortOrder: typeof props.defaultSortColumn === 'number' ? props.defaultSortOrder ?? 'Ascending' : props.defaultSortColumn?.sortOrder ?? props.defaultSortOrder ?? 'Ascending',
    lastPropLoad: moment().valueOf(),
    lastRowFilterUpdate: moment().valueOf(),
    instantFilters: {},
    rowsPerPage: props.defaultPagination != null ? props.defaultPagination : Array.isArray(props.pagination) ? props.pagination[0] : 50,
    page: 0,
    menuAnchor: undefined,
    currentMode: 'view',
    bulkSelectedRows: {},
    bulkConfirm: false
  };

  const [localState, setLocalState] = React.useState(defaultLocalState);

  const handlePageChange = (_event: unknown, newPage: number) => setLocalState((old) => ({
    ...old,
    page: newPage
  }));

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newRowsPerPage = +event.target.value;

    setLocalState((old) => ({
      ...old,
      page: Math.floor((old.page * old.rowsPerPage) / newRowsPerPage),
      rowsPerPage: newRowsPerPage
    }));
  };

  // take in a sorted list, or use the current sortedRows, and update rows to be shown by filters
  const sortedFilterRows = (rows?: string[]): { sortedRows: ISortedRow[]; displayRows: string[] } => {
    const sortedRows: ISortedRow[] = [];
    const displayRows: string[] = [];

    if (rows == null) {
      localState.sortedRows.forEach((r) => {
        const show = objectFilterString(props.rowData[r.rowKey], debounceQuickTextFilter) && filterColumn(props.rowData[r.rowKey], props.columnFilter);

        sortedRows.push({
          rowKey: r.rowKey,
          filterShow: show
        });

        if (show) displayRows.push(r.rowKey);
      });
    } else {
      rows.forEach((r) => {
        const show = objectFilterString(props.rowData[r], debounceQuickTextFilter) && filterColumn(props.rowData[r], props.columnFilter);

        sortedRows.push({
          rowKey: r,
          filterShow: show
        });

        if (show) displayRows.push(r);
      });
    }

    return {
      sortedRows,
      displayRows
    };
  };

  const handleRowsChanged = () => {
    setLocalState((old) => ({
      ...old,
      currentMode: 'view',
      bulkDeleteComment: undefined,
      bulkSelectedRows: {},
      bulkConfirm: false
    }));
  };

  React.useEffect(() => {
    handleRowsChanged();
  }, [props.rowData]);

  const handleFilterColumn = () => {
    setLocalState((old) => ({
      ...old,
      ...sortedFilterRows(),
      page: 0
    }));
  };

  React.useEffect(() => {
    handleFilterColumn();

    return () => { };
  }, [props.columnFilter, debounceQuickTextFilter]);

  const keysToSort = (reset?: boolean): string[] => (Object.keys(props.rowData).length !== localState.sortedRows.length || reset ? Object.keys(props.rowData) : localState.sortedRows.map((v) => v.rowKey));

  const sortRows = (column: ICustomSelectColumnMap | ICustomDisplayDefinition, order: ColumnOrderType): string[] => {
    const sortFactor = order === 'Descending' ? -1 : 1;

    if (column.sort != null) {
      return keysToSort().sort((a, b) => column.sort!(props.rowData[a], props.rowData[b], isICustomSelectColumnMap(column) ? column : undefined) * sortFactor);
    }

    return keysToSort().sort((a, b) => sortFunction(props.rowData[a][column.columnAlias], props.rowData[b][column.columnAlias], isICustomSelectColumnMap(column) ? column : undefined) * sortFactor);
  };

  const handleSortColumn = (column?: ICustomSelectColumnMap | ICustomDisplayDefinition) => {
    const sortColumn = column || localState.sortColumn || (typeof props.defaultSortColumn === 'number' ? props.displayColumns[props.defaultSortColumn] : props.defaultSortColumn?.column);

    if (sortColumn == null) {
      // if sortColumn is undefined, just push the unsorted data into the new table to be used by filters.
      setLocalState((old) => ({
        ...old,
        ...sortedFilterRows(keysToSort(true)),
        lastPropLoad: props.rowDataLastUpdate
      }));
    } else {
      // if same as currently sorted column, swap between ascending and descending
      if (localState.sortColumn !== undefined && localState.sortColumn.columnAlias === sortColumn.columnAlias && localState.sortedRows.length === Object.keys(props.rowData).length) {
        const reversedOrder: ColumnOrderType = localState.sortOrder === 'Descending' ? 'Ascending' : 'Descending';

        setLocalState((old) => ({
          ...old,
          ...sortedFilterRows(sortRows(sortColumn, reversedOrder)),
          sortOrder: reversedOrder,
          lastPropLoad: props.rowDataLastUpdate
        }));
      } else {
        const newOrder: ColumnOrderType = column == null ? defaultLocalState.sortOrder : 'Ascending';

        setLocalState((old) => ({
          ...old,
          ...sortedFilterRows(sortRows(sortColumn, newOrder)),
          sortColumn,
          sortOrder: newOrder,
          lastPropLoad: props.rowDataLastUpdate
        }));
      }
    }
    // if no sort provided, a re-sort from a data change is being called. If no sortColumn in state, was not sorted and leave as-is. If was sorted asc and clicked again, sort removed.
  };

  const getSortDirection = (column: ICustomSelectColumnMap | ICustomDisplayDefinition): 'asc' | 'desc' | undefined => {
    if (localState.sortColumn === undefined || localState.sortColumn.columnAlias !== column.columnAlias) return undefined;

    return localState.sortOrder === 'Ascending' ? 'asc' : 'desc';
  };

  React.useEffect(() => {
    console.log('<AutoGrid> state and props', { localState, props });

    handleSortColumn();

    return () => { };
  }, [props.rowDataLastUpdate]);

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (typeof props.onSelectAllClick !== 'undefined') {
      const newSelected: AutoLoadKeyDisplay = {};

      if (event.target.checked) {
        objectEach(props.rowData, (k, r) => {
          newSelected[k] = {
            display: getDisplayField(r, k, props.displayField),
            rowData: r
          };
        });
      }

      props.onSelectAllClick({
        event,
        checked: event.target.checked,
        updatedSelectedRows: newSelected
      });
    }
  };

  const handleRowClick = (event: React.MouseEvent<unknown>, key: string, display: string, rowData: { [x: string]: any }) => {
    console.log(`Row click for key: ${key}, name: ${display}`);

    if (localState.currentMode !== 'view') {
      const selectedRows = { ...localState.bulkSelectedRows };

      if (Object.prototype.hasOwnProperty.call(selectedRows, key)) {
        delete selectedRows[key];
      } else {
        selectedRows[key] = {
          display,
          rowData
        };
      }

      setLocalState((old) => ({
        ...old,
        bulkSelectedRows: selectedRows
      }));
    } else {
      if (props.onRowClick !== undefined) {
        if (props.selectedRows === undefined) {
          props.onRowClick({
            event,
            id: key,
            name: display,
            updatedSelectedRows: {
              [key]: {
                display,
                rowData
              }
            }
          });
        } else {
          const newSelected = { ...props.selectedRows };

          if (Object.prototype.hasOwnProperty.call(newSelected, key)) {
            delete newSelected[key];
          } else {
            newSelected[key] = {
              display,
              rowData
            };
          }

          props.onRowClick({
            event,
            id: key,
            name: display,
            updatedSelectedRows: newSelected
          });
        }
      }
    }
  };

  const menuKey = (isPre: boolean, key: string | number) => `${isPre ? 'pre' : 'post'}-automenu-${key}`;

  interface IMenus {
    row: AutoRow | undefined;
    anchor: HTMLElement | undefined;
    open: {
      [index: string]: boolean
    };
  }

  const [menus, setMenus] = React.useState<IMenus>({
    row: undefined,
    anchor: undefined,
    open: {
      ...(props.preActions == null || !Array.isArray(props.preActions) ? {} : arrayMapObject(props.preActions, (i, v) => Array.isArray(v) ? [menuKey(true, i), false] : undefined)),
      ...(props.postActions == null || !Array.isArray(props.postActions) ? {} : arrayMapObject(props.postActions, (i, v) => Array.isArray(v) ? [menuKey(false, i), false] : undefined))
    }
  });

  const handleMenuClose = () => setMenus((old) => ({
    row: undefined,
    anchor: undefined,
    open: objectMap(old.open, () => false)
  }));

  const rowBoolResult = (row: AutoRow, key: string | number, test?: boolean | RowBoolean, extension?: any) => {
    if (test == null) return false;

    if (typeof test === 'boolean') return test;

    return test(row, key, props.profile, extension);
  };

  const buildMenus = (index: number, isPre: boolean, buttons: IActionButton[]): React.ReactElement => {
    const key = menuKey(isPre, index);

    return (
      <Menu
        key={key}
        id={key}
        anchorEl={menus.anchor}
        anchorOrigin={{ horizontal: isPre ? 'left' : 'right', vertical: 'top' }}
        transformOrigin={{ horizontal: isPre ? 'left' : 'right', vertical: 'top' }}
        keepMounted
        open={menus.open[key] || false}
        onClose={handleMenuClose}
      >
        {buttons.map((b) => {
          return rowBoolResult(menus.row!, '', b.hidden == null ? false : b.hidden(props.profile, menus.row, props.stateExtension), props.stateExtension) ? undefined : (
            <MenuItem
              key={b.title}
              onClick={() => {
                b.onClick(menus.row!, props.stateExtension);
                handleMenuClose();
              }}
              disabled={rowBoolResult(menus.row!, '', b.disabled, props.stateExtension)}
            >
              <ListItemIcon>{b.icon != null ? b.icon : b.iconFunction != null ? b.iconFunction(menus.row!, props.stateExtension) : undefined}</ListItemIcon>
              <Typography>{b.title}</Typography>
            </MenuItem>
          );
        })}
      </Menu>
    );
  };

  const renderBulkActionCell = (row: AutoRow, rowKey: string | number, displayKey: string, button: IActionButton) => {
    if (button.icon == null && button.iconFunction == null) return undefined;

    if (localState.currentMode !== 'view') {
      const rowSelected = Object.prototype.hasOwnProperty.call(localState.bulkSelectedRows, displayKey);

      if (button.title === 'Delete') {
        return localState.currentMode === 'bulk-delete' ? <Checkbox checked={rowSelected} disabled={props.disabledSelects === true} /> : <IconButton disabled>{button.icon}</IconButton>;
      }

      if (button.title === 'Edit') {
        return localState.currentMode === 'bulk-edit' ? <Checkbox checked={rowSelected} disabled={props.disabledSelects === true} /> : <IconButton disabled>{button.icon}</IconButton>;
      }
    }

    if (button.iconFunction != null) return button.iconFunction(row, rowKey, props.stateExtension);

    return (
      <IconButton
        title={button.title}
        disabled={rowBoolResult(row, rowKey, button.disabled, props.stateExtension)}
        aria-label={button.title}
        onClick={(e) => {
          e.stopPropagation();
          button.onClick(row, rowKey, props.stateExtension);
        }}
      >
        {button.icon}
      </IconButton>
    );
  };

  const buildActions = (rowkey: string | number, isPre: boolean, buttons: IActionButton | IActionButton[], row: AutoRow, displayKey: string): React.ReactElement => {
    const key = menuKey(isPre, rowkey);

    if (Array.isArray(buttons)) {
      return (
        <TableCell align="center" padding="checkbox" key={key} className={props.dense ? props.classes.denseCell : undefined}>
          <IconButton
            aria-controls=""
            aria-haspopup={true}
            disabled={localState.currentMode !== 'view'}
            onClick={(e) => {
              e.stopPropagation();
              setMenus((old) => {
                return {
                  row,
                  anchor: e.currentTarget,
                  open: objectMap(old.open, (k) => {
                    return k === key;
                  })
                };
              });
            }}
          >
            <MoreVert />
          </IconButton>
        </TableCell>
      );
    }

    if (rowBoolResult(row, rowkey, buttons.hidden == null ? false : buttons.hidden(props.profile, row, props.stateExtension), props.stateExtension)) {
      return <TableCell key={key} />;
    }

    if (buttons.icon != null && rowBoolResult(row, rowkey, buttons.disabled, props.stateExtension)) {
      return (
        <TableCell align="center" padding="checkbox" key={key} className={props.dense ? props.classes.denseCell : undefined}>
          {buttons.icon}
        </TableCell>
      );
    }

    return (
      <TableCell align="center" padding="checkbox" key={key} className={props.dense ? props.classes.denseCell : undefined}>
        {renderBulkActionCell(row, rowkey, displayKey, buttons)}
      </TableCell>
    );
  };

  // const isTableMenuRequired = (): boolean => !!props.tableNoExcel;

  const handleOpenTableMenu = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    console.log('open menu called', e.currentTarget);

    setLocalState((old) => ({
      ...old,
      menuAnchor: e.currentTarget
    }));
  };

  const handleCloseTableMenu = () => setLocalState((old) => ({
    ...old,
    menuAnchor: undefined
  }));

  const handleExportExcel = () => {
    const cols = props.displayColumns.filter((c) => (c.hidden == null ? true : typeof c.hidden === 'boolean' ? c.hidden : !c.hidden(props.profile, undefined, props.stateExtension)));

    const res = {
      fileName: props.exportTitle,
      headers: cols.map((x) => (typeof x.columnTitle === 'string' ? x.columnTitle : x.columnTitle.export )),
      values: localState.displayRows.map((key) => cols.map((column) => getCellDisplay({ row: props.rowData[key], stateExtension, key, column })))
    };

    console.log(res);
    postGenerateExcel(res);
  };

  const buildTableMenu = () => (
    // !isTableMenuRequired() ? undefined :
    <Menu
      id="table-menu"
      anchorEl={localState.menuAnchor}
      anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
      transformOrigin={{ horizontal: 'left', vertical: 'top' }}
      keepMounted
      open={localState.menuAnchor != null}
      onClose={handleCloseTableMenu}
      onClick={handleCloseTableMenu}
    >
      <MenuItem key="excel-download" onClick={handleExportExcel}>
        <ListItemIcon>
          <GetApp />
        </ListItemIcon>
        <Typography>Download as Excel</Typography>
      </MenuItem>
    </Menu>
  );

  const setMode = (mode: AutoGridMode) => {
    setLocalState((old) => ({
      ...old,
      currentMode: mode,
      bulkSelectedRows: {}
    }));
  };

  const renderBulkActionHeader = (actionTitle: string) => {
    // -FEATURE- Enable later for Bulk Edit
    const bulkEditFeature = false;

    if (actionTitle === 'Delete') {
      if (localState.currentMode === 'view' && props.bulkDelete) {
        return (
          <IconButton title="Bulk Delete Mode" onClick={() => setMode('bulk-delete')}>
            <MdDeleteSweep />
          </IconButton>
        );
      }

      if (localState.currentMode !== 'view') {
        return (
          <IconButton title="Cancel Bulk Action" onClick={() => setMode('view')}>
            <MdClear />
          </IconButton>
        );
      }
    }

    if (actionTitle === 'Edit') {
      if (bulkEditFeature && localState.currentMode === 'view' && props.bulkEdit) {
        return (
          <IconButton title="Bulk Edit Mode" onClick={() => setMode('bulk-edit')}>
            <MdEditNote />
          </IconButton>
        );
      }

      if (localState.currentMode !== 'view') {
        return (
          <IconButton title="Confirm Bulk Action" disabled={isEmpty(localState.bulkSelectedRows)} onClick={() => setLocalState((old) => ({ ...old, bulkConfirm: true }))}>
            <MdDoneAll />
          </IconButton>
        );
      }
    }

    return undefined;
  };

  const actionHeaderColumns = (isPre: boolean, actions?: IActionButton | (IActionButton[] | IActionButton)[]): React.ReactElement | React.ReactElement[] | undefined => {
    if (actions == null) {
      if (isPre) return undefined;

      return (
        <TableCell
          // padding="checkbox"
          align="right"
          key="no-action-menu"
          className={props.dense ? props.classes.denseCell : undefined}
        >
          <IconButton title="table options" onClick={handleOpenTableMenu}>
            <MenuIcon />
          </IconButton>
        </TableCell>
      );
    }

    if (Array.isArray(actions)) {
      return actions.map((action, i) => (
        <TableCell
          // padding="checkbox"
          align="right"
          key={menuKey(isPre, i)}
          className={props.dense ? props.classes.denseCell : undefined}
        >
          {renderBulkActionHeader((action as IActionButton).title)}
          {!isPre && i === actions.length - 1 ? (
            <IconButton title="table options" onClick={handleOpenTableMenu}>
              <MenuIcon />
            </IconButton>
          ) : undefined}
        </TableCell>
      ));
    }

    return actionHeaderColumns(isPre, [actions]);
  };

  const actionRowColumns = (isPre: boolean, actions: IActionButton | (IActionButton[] | IActionButton)[] | undefined, key: string, row: AutoRow): React.ReactElement | React.ReactElement[] | undefined => {
    if (actions == null) {
      // if (isPre)
      return undefined;
      // return actionRowColumns(isPre, [{ icon: <p />, title: '', onClick: () => { } }], row);
    }

    if (Array.isArray(actions)) {
      return actions.map((a, i) => buildActions(i, isPre, a, row, key)); // key, display));
    }

    return actionRowColumns(isPre, [actions], key, row); // key, display);
  };

  const isSelected = (key: string) => (props.selectedRows == null ? false : Object.prototype.hasOwnProperty.call(props.selectedRows, key));

  const divHeight = (): React.CSSProperties => ({ minHeight: props.minHeight ?? 25, maxHeight: props.maxHeight ?? 'calc(100vh - 80px)' });

  React.useEffect(() => {
    console.log('<AutoGrid/> state and props: ', { localState, props });
  }, [localState]);

  const getCellAlign = (column: ICustomSelectColumnMap | ICustomDisplayDefinition): 'inherit' | 'left' | 'center' | 'right' | 'justify' => {
    if (column.cellAlign != null) return column.cellAlign;

    return 'left';
  };

  const getPageOptions = () => {
    const arr = Array.isArray(props.pagination) ? props.pagination : [50, 100, 250];

    if (props.defaultPagination != null && arr.indexOf(props.defaultPagination) < 0) arr.push(props.defaultPagination);

    return arr.sort((a, b) => (b - a));
  };

  const actionCount = () =>
    (props.selectedRows == null ? 0 : 1) + // checkboxes
    (props.rowNumbers === true ? 1 : 0) +
    (props.preActions == null ? 0 : Array.isArray(props.preActions) ? props.preActions.length : 1) +
    (props.postActions == null ? 0 : Array.isArray(props.postActions) ? props.postActions.length : 1);

  const handleBulkDelete = () => {
    if (typeof props.baseTable === 'undefined') return;

    const table = typeof props.baseTable === 'string' ? props.db.database.getTableByCombinedName(props.baseTable) : props.db.database.getTableById(props.baseTable);

    autoChange({
      fetchConfigRequest: props.fetchConfigRequest,
      createAlertBulk: props.createAlertBulk,
      page: props.ui.title,
      title: `Delete from ${table.title}`,
      description: `Bulk Delete of ${Object.keys(localState.bulkSelectedRows).length} Row(s)`,
      changes: {
        action: 'Delete',
        description: localState.bulkDeleteComment,
        changeSets: Object.keys(localState.bulkSelectedRows).map((key) => ({
          tableId: table.tableId,
          type: 'Delete',
          action: 'BulkDeleteConfirmPrompt',
          changes: [],
          key: +key
        }))
      }
    }).then((res) => {
      if (props.onBulkDelete) props.onBulkDelete(Object.keys(localState.bulkSelectedRows), res.success, res.messages);

      setLocalState((old) => ({
        ...old,
        currentMode: 'view',
        bulkDeleteComment: undefined,
        bulkSelectedRows: {},
        bulkConfirm: false
      }));
    });
  };

  const renderBulkConfirm = () => {
    const bulkSeletedKeys = Object.keys(localState.bulkSelectedRows);
    const bulkSelectedCount = bulkSeletedKeys.length;

    const handleRemove = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, key: string) => {
      event.stopPropagation();

      const selectedRows = { ...localState.bulkSelectedRows };
      delete selectedRows[key];

      setLocalState((old) => ({
        ...old,
        bulkSelectedRows: selectedRows,
        bulkConfirm: bulkSelectedCount === 1 ? false : true
      }));
    };

    const removeButton = (_row: AutoRow, key: string) => {
      return (
        <IconButton edge="end" onClick={(event) => handleRemove(event, key)}><MdClear/></IconButton>
      );
    };

    const handleBulkDeleteCommentChange = (bulkDeleteComment: string) => setLocalState((old) => ({ ...old, bulkDeleteComment }));

    return (
      <Dialog open={localState.bulkConfirm} onClose={() => setLocalState((old) => ({ ...old, bulkConfirm: false }))} fullWidth maxWidth="lg">
        <DialogTitle>{`Confirm ${localState.currentMode === 'bulk-delete' ? 'delete' : 'edit'} of ${bulkSelectedCount} row${bulkSelectedCount === 1 ? '' : 's'}?`}</DialogTitle>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <List key="bulk-delete-rows" dense>
                {bulkSeletedKeys.map((key) => {
                  const row = localState.bulkSelectedRows[key];

                  return (
                    <ListItemButton key={`bulkConfirm_${key}`} onClick={() => setLocalState((old) => ({ ...old, bulkDeleteDetailKey: key }))}>
                      <ListItem secondaryAction={removeButton(row, key)}>
                        <ListItemText primary={row.display} />
                      </ListItem>
                    </ListItemButton>
                  );
                })}
              </List>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <TextField label="Comment on deletion" value={localState.bulkDeleteComment ?? ''} rows={4} fullWidth onChange={(event) => handleBulkDeleteCommentChange(event.currentTarget.value)}/>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setLocalState((old) => ({ ...old, bulkConfirm: false }))} color="primary">
            Cancel
          </Button>
          <Button onClick={() => handleBulkDelete()} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const renderBulkDetail = () => {
    if (typeof localState.bulkDeleteDetailKey === 'undefined') return undefined;

    const detailRow = localState.bulkSelectedRows[localState.bulkDeleteDetailKey];

    const renderDisplayColumns = (key: string) => {
      return props.displayColumns.map((col) => {
        if (props.rowData == null) return undefined;

        return (
          <Grid key={`${col.columnAlias}_title`} item xs={12} sm={6} md={4}>
            <DisplayField label={typeof col.columnTitle === 'string' ? col.columnTitle : col.columnTitle.export} value={getCellDisplay({ row: detailRow.rowData, key, column: col, forceText: true, linkNewPage: true }) ?? ''} />
          </Grid>
        );
      });
    };

    return (
      <Dialog open={typeof localState.bulkDeleteDetailKey !== 'undefined'} onClose={() => setLocalState((old) => ({ ...old, bulkDeleteDetailKey: undefined }))} fullWidth maxWidth="lg">
        <DialogTitle>Detail View</DialogTitle>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h6">{detailRow.display}</Typography>
            </Grid>
            {renderDisplayColumns(localState.bulkDeleteDetailKey)}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setLocalState((old) => ({ ...old, bulkDeleteDetailKey: undefined }))} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return props.db.loading || props.db.database.lastUpdated == null || props.displayColumns.length === 0 ? (
    <LoadingScreen />
  ) : props.db.errors !== undefined ? (
    <span>{props.db.errors}</span>
  ) : (
    <>
      <TableContainer className={props.classes.tableWrapper} style={divHeight()}>
        <Table style={{ marginBottom: props.marginBottom ?? 25 }} stickyHeader size="small">
          <TableHead>
            <TableRow className={clsx(props.classes.tableRow, props.classes.tableRowHover)}>
              {!props.rowNumbers ? undefined : (
                <TableCell padding="checkbox" key="0-selected-checkbox" className={props.dense ? props.classes.denseCell : undefined}>
                  #
                </TableCell>
              )}
              {props.selectedRows == null ? undefined : (
                <TableCell padding="checkbox" key="0-selected-checkbox" className={props.dense ? props.classes.denseCell : undefined}>
                  <Checkbox
                    onChange={handleSelectAll}
                    indeterminate={Object.keys(props.selectedRows).length > 0 && Object.keys(props.selectedRows).length < Object.keys(props.rowData).length}
                    checked={Object.keys(props.rowData).length === Object.keys(props.selectedRows).length && Object.keys(props.rowData).length > 0}
                    inputProps={{ 'aria-label': 'Select All', title: 'Select All' }}
                    disabled={props.disabledSelects === true}
                  />
                </TableCell>
              )}
              {actionHeaderColumns(true, props.preActions)}
              {props.displayColumns
                .filter((c) => (c.hidden == null ? true : typeof c.hidden === 'boolean' ? c.hidden : !c.hidden(props.profile, undefined, props.stateExtension)))
                .map((column) => {
                  return (
                    <TableCell key={column.columnAlias} align="left" sortDirection={getSortDirection(column)} className={props.dense ? props.classes.denseCell : undefined}>
                      <TableSortLabel active={!!getSortDirection(column)} direction={getSortDirection(column)} onClick={() => handleSortColumn(column)}>
                        {typeof column.columnTitle === 'string' ? column.columnTitle : column.columnTitle.display}
                      </TableSortLabel>
                    </TableCell>
                  );
                })}
              {actionHeaderColumns(false, props.postActions)}
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.keys(props.rowData).length === 0 || localState.displayRows.length === 0 || props.rowDataLoading || localState.lastPropLoad !== props.rowDataLastUpdate ? (
              <TableRow>
                <TableCell
                  colSpan={props.displayColumns.filter((c) => (c.hidden == null ? true : typeof c.hidden === 'boolean' ? c.hidden : !c.hidden(props.profile, undefined, props.stateExtension))).length + (props.hideMenuActions ? 0 : 1) + actionCount()}
                  className={props.dense ? props.classes.denseCell : undefined}
                >
                  {props.rowDataLoading ? <LoadingScreen /> : <Typography className={props.classes.emptyMessage}>{props.emptyMessage || 'Please search to see rows'}</Typography>}
                </TableCell>
              </TableRow>
            ) : (
              (localState.displayRows == null ? [] : props.pagination == null ? localState.displayRows : localState.displayRows.slice(localState.page * localState.rowsPerPage, localState.page * localState.rowsPerPage + localState.rowsPerPage)).map(
                (key, idx1) => {
                  const row = props.rowData[key];
                  if (row == null) return undefined;
                  const isItemChecked = isSelected(key);
                  return (
                    <TableRow
                      className={clsx(props.selectedRows != null || props.onRowClick != null ? props.classes.tableRow : props.classes.noClick, props.rowClasses != null ? props.rowClasses(row) : undefined, props.dense ? props.classes.denseCell : undefined)}
                      hover
                      role={props.selectedRows == null ? undefined : 'checkbox'}
                      tabIndex={-1}
                      key={key}
                      onClick={(event) => handleRowClick(event, key, getDisplayField(row, key, props.displayField), row)}
                    >
                      {!props.rowNumbers ? undefined : (
                        <TableCell padding="checkbox" className={props.dense ? props.classes.denseCell : undefined}>
                          {1 + idx1 + (props.pagination == null ? 0 : localState.page * localState.rowsPerPage)}
                        </TableCell>
                      )}
                      {props.selectedRows == null ? undefined : (
                        <TableCell padding="checkbox" className={props.dense ? props.classes.denseCell : undefined}>
                          <Checkbox checked={isItemChecked} inputProps={{ 'aria-labelledby': '' }} disabled={props.disabledSelects === true} />
                        </TableCell>
                      )}
                      {actionRowColumns(true, props.preActions, key, row)}
                      {props.displayColumns
                        .filter((c) => (c.hidden == null ? true : typeof c.hidden === 'boolean' ? c.hidden : !c.hidden(props.profile, row, props.stateExtension)))
                        .map((column, idx2) => (
                          <TableCell
                            key={column.columnAlias}
                            align={getCellAlign(column)}
                            className={props.dense ? props.classes.denseCell : undefined}
                            colSpan={idx2 === props.displayColumns.filter((c) => (c.hidden == null ? true : typeof c.hidden === 'boolean' ? c.hidden : !c.hidden(props.profile, row, props.stateExtension))).length - 1 && props.postActions == null ? 2 : 1}
                          >
                            {getCellDisplay({ row, key, column, stateExtension })}
                          </TableCell>
                        ))}
                      {actionRowColumns(false, props.postActions, key, row)}
                    </TableRow>
                  );
                }
              )
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {props.pagination == null || props.pagination === false ? undefined : (
        <TablePagination
          rowsPerPageOptions={getPageOptions()}
          count={localState.displayRows.length}
          rowsPerPage={localState.rowsPerPage}
          page={localState.page}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleChangeRowsPerPage}
          component="div"
        />
      )}
      {buildTableMenu()}
      {props.preActions == null || !Array.isArray(props.preActions) ? undefined : props.preActions.map((a, i) => (Array.isArray(a) ? buildMenus(i, true, a) : undefined))}
      {props.postActions == null || !Array.isArray(props.postActions) ? undefined : props.postActions.map((a, i) => (Array.isArray(a) ? buildMenus(i, false, a) : undefined))}
      {renderBulkConfirm()}
      {renderBulkDetail()}
    </>
  );
};

export const mapConfigFetchToProps = { fetchConfigRequest, refreshCacheData, createAlertBulk, fetchProfile };
export default compose<ComponentType<IAutoGridProps & IAutoGridEvents & IGridMenuOptions>>(withStyles(styles), connect(mapProfileDbFromAppState, mapConfigFetchToProps))(AutoGrid);
